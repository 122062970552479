<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      title="Videos"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  Videos
                </div>

                <div class="subtitle-1 font-weight-light">
                  Administre sus videos
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :custom-filter="customFilter"
          >
            <template v-slot:item.video_category="{ item }">
              <v-chip color="primary" v-if="item.video_category">
                {{ item.video_category.name }}
              </v-chip>
              <v-chip v-else>
                Sin asignar
              </v-chip>
            </template>
            <template v-slot:item.video.size="{ item }">
              {{ (item.video.size / 1024).toFixed(2) }} MB
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <!--
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
                -->

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <item-add />
    <item-edit />
    <item-detail />
    <item-delete />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  import latinize from 'latinize'
  import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'ItemIndex',
    mixins: [customFilter],
    components: {
      ItemAdd: () => import('./VideosAdd'),
      ItemEdit: () => import('./VideosEdit'),
      ItemDetail: () => import('./ItemDetail'),
      ItemDelete: () => import('./VideosDelete'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: "Categoría",
            value: 'video_category',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: "Tamaño",
            value: 'video.size',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
      }
    },
    computed: {
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()
        this.items = await this.getData()
        this.toggleLoader()
      },
      async getData() {
        let data = []
        await this.$http.get(route("videos?populate=*"))
          .then(response => {
            if (response.body.data) {
              data = response.body.data
            } else {
              this.processError(response.body)
            }
          }, error => this.processError(error))
        return data
      },
      openItemAdd () {
        EventBus.$emit('videos-add')
      },
      openItemEdit (item) {
        EventBus.$emit('videos-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('arbitros-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('trainings-delete', item)
      },
    },
  }
</script>

<style scoped>

</style>
